import React from 'react';
import footerIMG from './../img/footer-img.png'
import {BankOutlined, MessageOutlined} from "@ant-design/icons";

function Footer() {

  const footerStyle = {
    margin: '40px',
  };

  const iconStyle = {
    fontSize: '50px'
  }

  return (
    <>
      <div id="footer" className="container-fluid footer">
        <div className="row">
          <div className="col-lg-4">
            <MessageOutlined
              style={iconStyle}
            />
            <h3>Email Us</h3>
            <p><a href="mailto:srh.ehrhardt.m17@gmail.com">srh.ehrhardt.m17@gmail.com</a></p>
          </div>
          <div className="col-lg-4">
            <BankOutlined
              style={iconStyle}
            />
            <h3>Lokalizacja</h3>
            <p>STOWARZYSZENIE REKONSTRUKCJI HISTORYCZNEJ EHRHARDT M17,
              <p>ul. Wł. Łokietka 31, 64 – 840 Budzyń</p>
              <p>              NIP 6070088233, REGON 383096279, KRS 0000781407</p>
              <p>              Nr konta: BS Chodzież, oddział w Budzyniu</p>
              <p>              89 8945 0002 2608 6583 2000 0010 Pokaż mniej</p>
            </p>
          </div>
          <div>
            <img style={footerStyle} src={footerIMG} alt=""/>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;