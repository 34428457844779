import React from 'react';
import { Image } from 'antd';
import a from "./../img/Galeria/1.jpg"
import b from "./../img/Galeria/2.jpg"
import c from "./../img/Galeria/3.png"
import d from "./../img/Galeria/4.png"
import e from "./../img/Galeria/5.png"
import f from "./../img/Galeria/6.png"
import g from "./../img/Galeria/7.jpg"
import h from "./../img/Galeria/8.jpg"
import j from "./../img/Galeria/9.jpg"
import k from "./../img/Galeria/10.jpg"

function Gallery () {
  return (
    <>
      <div className="container-fluid wrapper"></div>
      <div className="container gallery">
        <div className="siteBG">
          <h3>Galeria</h3>
          <div className="galleryBox">
            <Image width={150} src={a}/>
            <Image width={150} src={b}/>
            <Image width={150} src={c}/>
            <Image width={150} src={d}/>
            <Image width={150} src={e}/>
            <Image width={150} src={f}/>
            <Image width={150} src={g}/>
            <Image width={150} src={h}/>
            <Image width={150} src={j}/>
            <Image width={150} src={k}/>
          </div>
        </div>
      </div>
    </>
  );
}

export default Gallery;
