import React from 'react';

function Media() {
  return (
    <div className="media">
      <>
        <div className="container siteBG mediaWrapper">
          <div>
            <h1>Media</h1>
            <h3>Biblioteka filmów zrealizowanych o pojeździe pancernym erhardtm1917</h3>
          </div>
<div>
  <iframe width="350px" height="215" src="https://www.youtube.com/embed/IYP3p1CU54g" title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen></iframe>

  <iframe width="350px" height="215" src="https://www.youtube.com/embed/sR01o8pnMGU" title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen></iframe>

  <iframe width="350px" height="215" src="https://www.youtube.com/embed/llaw-v8Pr6A" title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen></iframe>

  <iframe width="350px" height="215" src="https://www.youtube.com/embed/UTl2_h1zSRg" title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen></iframe>

  <iframe width="350px" height="215" src="https://www.youtube.com/embed/PJzgWDGmnkU" title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen></iframe>

  <iframe width="350px" height="215" src="https://www.youtube.com/embed/4tg3fnAOt9Q" title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen></iframe>

  <iframe width="350px" height="215" src="https://www.youtube.com/embed/PZ-HnkYjYR4" title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen></iframe>

  <iframe width="350px" height="215" src="https://www.youtube.com/embed/YZ5CIZQ3l5g" title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen></iframe>

  <iframe width="350px" height="215" src="https://www.youtube.com/embed/zbShzkT2qxE" title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen></iframe>

  <iframe width="350px" height="215" src="https://www.youtube.com/embed/MFScYJ40OSY" title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen></iframe>
</div>
        </div>
      </>
    </div>
  );
}

export default Media;
