import React, {useEffect} from 'react';
import '../styles/wrapper.scss'
import '../styles/home.scss'
import Aos from "aos";
import {RightOutlined} from "@ant-design/icons";
import Timewrap from './../components/Timewrap'
import AboutProduct from './../components/AboutProduct'
import heroIMG from './../img/tank-nobg.png'
import ReactAudioPlayer from 'react-audio-player';
import lektorAudio from './../media/lektor.wav'

function Home() {

  useEffect(() => {
    Aos.init(
      {offset: 0}
    );
  }, [])

  return (
    <>
      <div className="header">
        <div className="heroIMG">
          <img src={heroIMG} alt=""/>
        </div>
        <div className="heroTitle">
          <h2>Ehrhardt</h2>
          <p>Poznaj historię zdobycia samochodu pancernego</p>
          <span><a href="#content">przejdź dalej</a> <RightOutlined /></span>
        </div>
        <div className="mouse_scroll">
          <div className="mouse">
            <div className="wheel"></div>
          </div>
          <div>
            <span className="m_scroll_arrows unu"></span>
            <span className="m_scroll_arrows doi"></span>
            <span className="m_scroll_arrows trei"></span>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="grid-temp">
          <div>
            <h2>Posłuchaj o bitwie pod Budzyniem
            </h2>
            <ReactAudioPlayer
              src={lektorAudio}
              autoPlay
              controls
            />
          </div>
        </div>
      </div>
      <Timewrap/>
      <AboutProduct/>
    </>
  );
}

export default Home;
