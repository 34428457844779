import React, { useState } from 'react';
import {Divider} from 'antd';
import object from './../img/erhardt-object.png'
import { Modal, Button } from 'antd';


function AboutProduct() {

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <div className="tableProduct_wrapper container-fluid">
        <Divider
          orientation="left"
          plain>
          Samochód pancerny
        </Divider>
        <h1><a target="_blank" rel="noreferrer" href="https://pl.wikipedia.org/wiki/Ehrhardt_E-V/4">Opis</a></h1>
        <p>
          Nazwa pochodzi od nazwiska słynnego niemieckiego konstruktora broni i uzbrojenia artyleryjskiego <a
          target="_blank" rel="noreferrer" href="https://pl.wikipedia.org/wiki/Heinrich_Erhardt">Heinricha Ehrhardta</a>. To on odpowiadając na zamówienie armii niemieckiej zaprojektował w 1915 roku pojazd który stał się jednym z najlepszych samochodów pancernych <a
          target="_blank" rel="noreferrer" href="https://pl.wikipedia.org/wiki/I_wojna_%C5%9Bwiatowa">I-szej Wojny Światowej</a>.
        </p>
        <div className="tableProduct">
          <div className="tableDesc_Object" data-aos="fade-right">
            <p>Masa 7,7 tony</p>
            <p>Długość 5,3 m</p>
            <p>Wysokość 2,85 m</p>
            <p>Szerokość 2,0 m</p>
          </div>
          <div className="tableDesc" data-aos="fade-left">
            <div>
              <p>
                Napęd 6-cylindrowy silnik Daimler, 80 KM<br />
                Napęd na wszystkie koła<br />
                Prędkość 61,3 km/h<br />
                Zasięg 250 km<br />
                Pancerz do 9mm<br />
                Uzbrojenie 5 x MG 08/15<br />
                Załoga 9 żołnierzy<br />
              </p>
            </div>
          </div>
          <img src={object} alt=""/>
        </div>

        <Button type="dashed" onClick={showModal}>Czytaj więcej</Button>
        <Modal title="Pełen Opis" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
          <p>
            W celu zmniejszenia kosztów produkcji konstrukcję oparto na podwoziu samochodu ciężarowego <a
            href="https://wofmd.com/2018/04/27/daimler-plattformwagen/">Daimler-BaK Plattformwagen M1914</a> używanego jako platforma armaty przeciwlotniczej <a
            href="https://www.wikiwand.com/ru/Krupp-Daimler_7,7cm_FlaK">7,7 cm Krupp</a>. Pojazd był imponujący i potężnie uzbrojony. Długi na 5,3 metra, wysoki na prawie trzy metry, uzbrojony w aż pięć karabinów maszynowych <a
            href="https://pl.wikipedia.org/wiki/Karabin_maszynowy_MG_08/15">MG 08/15</a> o szybkostrzelności do 600 strzałów na minutę.
          </p>
          <p>
            Cztery karabiny osadzono w strzelnicach w kadłubie natomiast piąty znajdował się w nieobrotowej wieży. Wieża ta posiadała cztery zamykane otwory strzelnicze z jarzmami do karabinu dzięki czemu strzelec mógł szybko zmieniać pole ostrzału. Pomimo swojej masy ponad 7,7 tony, silnik benzynowy o mocy 80 KM napędzający wszystkie cztery koła pojazdu zapewniał prędkość na ubitej drodze ok 60 km/h!
          </p>
          <p> Dziewięcioosobową załogę chronił pancerz ze stalowych płyt o grubości do 9 mm. W 1916 roku pierwsze egzemplarze wzięły udział w walkach na terenie Rumunii.</p>
          <p>W czasie wojny pojazd ulegał modyfikacjom. Zmniejszono masę o 1,75 tony poprawiając tym samym mobilność w terenie, poprawiono przedni pancerz, podwozie zostało zabezpieczone płytą pancerną chroniącą skrzynię biegów. Najważniejszą zmianą było zamontowanie nowej obrotowej wieży oraz montaż bezprzewodowego radia!  Łącznie wyprodukowano 33 egzemplarze.</p>
          < >
            <p>
              Z chwilą zakończenia I Wojny Światowej pokonanym Niemcom pozwolono pozostawić na wyposażeniu samochody pancerne. Było to podyktowane rosnącym zagrożeniem komunizmu jaki rozlewał się po Europie. Już w 1919 roku jednostki <a
              href="https://pl.wikipedia.org/wiki/Freikorps">Freikorpsu</a> używają m.in.  Ehrhardty do krwawego stłumienia komunistycznego powstania w <a
              href="https://pl.wikipedia.org/wiki/Berlin">Berlinie</a> zwanego Spartakusaufstand <a
              href="https://pl.wikipedia.org/wiki/Powstanie_Spartakusa_(1919)">„Powstaniem Spartakusa”</a>
            </p>
          </>
          < >
            <p>Niemcy używali Ehrhardty jeszcze długie lata. Otóż w 1939 roku czyli ponad dwadzieścia lat od produkcji pojazdy te występują jeszcze na liście pojazdów <a
              href="https://de.wikipedia.org/wiki/Polizei_(Deutschland)#Polizei_im_Nationalsozialismus_1933%E2%80%931945">policji niemieckiej</a>.</p>
          </>
        </Modal>
        <Button><a href="/products">Czytaj o samochodzie</a></Button>
      </div>
    </>

  );
}

export default AboutProduct;