import React from "react";
import Navbar from "./components/Navbar";
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import Home from './pages/Home'
import Media from './pages/Media'
import Products from './pages/Products'
import Gallery from './pages/Gallery'
import './styles/home.scss'
import './styles/bootstrap-grid.css'
import * as BiIcons from 'react-icons/bi';
import Footer from "./components/Footer";
import ReactGA from 'react-ga';
// Antd
import {BackTop} from 'antd';

function App() {

  ReactGA.initialize('G-P9DHJQMQ17', []);

  const styleBacktop = {
    height: 40,
    width: 40,
    lineHeight: '40px',
    borderRadius: 4,
    backgroundColor: '#1088e9',
    color: '#fff',
    textAlign: 'center',
    fontSize: 14,
    position: 'fixed',
    bottom: '0',
    right: '0',
    margin: '20px',
  };

  return (
    <>
      <div className="App">
        <BackTop>
          <div style={styleBacktop}><BiIcons.BiArrowToTop/></div>
        </BackTop>
        <Router>
          <div className="topMenu">
            <Navbar/>
          </div>
          <Switch>
            <div>
              <Route path='/' exact component={Home}/>
              <Route path='/media' exact component={Media}/>
              <Route path='/products' exact component={Products}/>
              <Route path='/galeria' exact component={Gallery}/>
            </div>
          </Switch>
          <Footer/>
        </Router>
      </div>
    </>
  );
}

export default App;