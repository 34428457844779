import React from 'react';
import {Timeline, Image, Button} from 'antd';
import firstIMG from './../img/wiki/Powstanie_wielkopolskie_1919.jpg'
import secondIMG from './../img/wiki/Ehrhardt_1.jpg'
import thirdIMG from './../img/wiki/Dwo.JPG'
import fourIMG from './../img/wiki/defilada.jpg'

function Timewrapper() {

  return (
    <>
      <Timeline
        mode="left"
        className="timeline container"
      >
        {/* 1918 */}
        <Timeline.Item
          dot={<>
            <span className="customTick"></span>
            <span className="customDate">1918</span>
          </>}
        >
          <div id="content" className="timelineCard" data-aos="fade-right">
            <Image
              src={firstIMG}
            />
            <h3>Wybuch Powstania Wielkopolskiego</h3>
            <span>data: 1918-12-27 16:40</span>
            <p>Dnia 27 grudnia roku Pańskiego 1918 nad grodem Przemysława zabrzmiał swym donośnym głosem złoty Róg Wolności. Echo tego symbolicznego rogu jako błyskawica rozległo się po całym zaborze pruskim
            </p>
            <Button><a href="/products#first">Czytaj więcej</a></Button>
          </div>
        </Timeline.Item>
        {/* 1919 */}
        <Timeline.Item
          dot={<>
            <span className="customTick"></span>
            <span className="customDate">1919</span>
          </>}
        >
          <div className="timelineCard" data-aos="fade-left">
            <Image
              src={thirdIMG}
            />
            <h3>Budzyń</h3>
            <span>data: 1919-01-05</span>
            <p>W niedzielę rano 5 stycznia 1919 roku do Budzynia przybyła gromadka Sokołów powstańców z Rogoźna z Wiktorem Skotarczakiem na czele i obsadziła Budzyń, uwalniając miasto nasze ze Szpon orła czarnego po półtorawiekowej niewoli.
            </p>
            <Button><a href="/products#budzyn">Czytaj więcej</a></Button>
          </div>
        </Timeline.Item>
        <Timeline.Item
          dot={<>
            <span className="customTick"></span>
            <span className="customDate">1919</span>
          </>}
        >
          <div className="timelineCard" data-aos="fade-right">
            <Image
              src={secondIMG}
            />
            <h3>Zdobycie Ehrhardta</h3>
            <span>data: 1919-02-07</span>
            <p>Zdobycie Pancernika. "Ich will bios eine Vergnügungsfahrt nach Budzyń unternehmen!" Dowództwo polskie wiedziało coś o owych przygotowaniach nieprzyjacielskich.
            </p>
            <Button><a href="/products#four">Czytaj więcej</a></Button>
          </div>
        </Timeline.Item>
        {/* 1919 */}
        {/* 1920 */}
        <Timeline.Item
          dot={<>
            <span className="customTick"></span>
            <span className="customDate">1920</span>
          </>}
        >
          <div className="timelineCard" data-aos="fade-left">
            <Image
              src={fourIMG}
            />
            <h3>Defilada</h3>
            <span>1920-03-01</span>
            <p>Defilada w Chodzieży. Wdzięczni chodzieżanie ufundowali 7 Pułkowi chorągiew pułkową. Jej poświęcenie i przekazanie miało miejsce w dniu 1 marca 1920 roku. Na rynku w Chodzieży zgromadzili się tego dnia wszyscy żołnierze pułku. O randze wydarzenia świadczyła obecność generała Józefa Dowbór-Muśnickiego.
            </p>
          </div>
        </Timeline.Item>
      </Timeline>
    </>
  );
}

export default Timewrapper;
