import React, {useState, useEffect} from 'react';
import * as HiIcons from 'react-icons/hi';
import * as AiIcons from 'react-icons/ai'
import {Link} from 'react-router-dom'
import {SidebarData} from "./SidebarData";
import './Navbar.scss';
import {IconContext} from "react-icons";

// switch mode

function Navbar() {

  const [sidebar, setSidebar] = useState(false)

  useEffect(() => {
    if (sidebar) {
      document.addEventListener('click', function handler() {
        setSidebar(false)
        this.removeEventListener('click', handler);
      })
    }
  }, [sidebar]);

  const showSidebar = () => setSidebar(!sidebar)
  return <IconContext.Provider className="menuButton" value={{}}>
    <div className="navbar">
      <Link to="#" className='menu-bars menu-bars_icon'>
        <HiIcons.HiMenuAlt2 onClick={showSidebar}/>
      </Link>
    </div>
    <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
      <ul className='nav-menu-items' onClick={showSidebar}>
        <li className="navbar-toggle">
          <Link to="#" className='menu-bars'>
            <AiIcons.AiOutlineCloseCircle/>
          </Link>
        </li>
        {SidebarData.map((item, index) => {
          return (
            <li key={index} className={item.cName}>
              <Link to={item.path}>
                {item.icon}
                <span>{item.title}</span>
              </Link>
            </li>
          )
        })}
      </ul>
    </nav>
  </IconContext.Provider>;
}

export default Navbar;