import React from 'react';
import { Divider, Menu, Dropdown, Button } from 'antd';

function Products() {

  const menu = (
    <Menu>
      <Menu.Item>
        <a  rel="noopener noreferrer" href="#first">Wybuch Powstania</a>
      </Menu.Item>
      <Menu.Item>
        <a  rel="noopener noreferrer" href="#second">Uwagi</a>
      </Menu.Item>
      <Menu.Item>
        <a  rel="noopener noreferrer" href="#third">Tworzenie II Baonu </a>
      </Menu.Item>
      <Menu.Item>
        <a  rel="noopener noreferrer" href="#four">Zdobycie Pancernika</a>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="wrapper">
      <div className="container blog align-items-center">
        <div>
          <h1>Zdobycie samochodu pancernego Ehrhardt M1917</h1>
          <Dropdown className="articleMenu" overlay={menu} placement="bottomCenter">
            <Button>Wybierz temat</Button>
          </Dropdown>
        </div>

        <div className="row article">
          <div className="col-lg-12">

            <p>Obszerne fragmenty z książki „Moje wspomnienia z walk o niepodległość Polski” Piotra Grzeczki byłego
              zbrojowniczego II batalionu 4-go Pułku Strzelców Wielkopolskich (58p.p.)
              (pisownia oryginalna)
              cała książka do przeczytania <a
                target="_blank" rel="noreferrer"
                href="https://www.wbc.poznan.pl/dlibra/docmetadata?showContent=true&id=131266">TU</a></p>
            <div id="first">
              <h4>Wybuch Powstania Wielkopolskiego i wejście powstańców do Budzynia</h4>
              <p>
                Wracając z wojny światowej, frontu zachodniego, ze zdumieniem przyglądałem się jak wojsko rabowało pociągi,
                magazyny, składnice it.p. Żołnierze zrywali oficerom odznaki, policja była rozbrojona i narażona na napaści
                motłochu. Żaden oficer czy urzędnik nie odważył się przeciw rozpętanej swawoli energiczniej wystąpić, bo
                zaraz odzywały się groźne okrzyki:
              </p>
              <p>
                Hautihm, gibihm, Messerraus (bij go,uderz go, dobądź noża it.d.) Stare brodziaste
                <a target="_blank" rel="noreferrer"
                   href="https://pl.wikipedia.org/wiki/Landsturm">landszturmany</a> obarczeni łupami (gdyby to szło
                zabraliby całe domy) cisnęli się do
                przepełnionych pociągów, a przemęczeni kolejarze z troską spoglądali czy szczęśliwie doprowadzą pociągi do
                miejsca przeznaczenia. Gdzie było spojrzeć wszędzie przewrót, wybryki, nieposłuszeństwo. W
                <a target="_blank" rel="noreferrer" href="https://pl.wikipedia.org/wiki/Berlin">Berlinie</a> sprzedawano
                widokówki przedstawiające
                uciekającego cesarza wraz z
                następcą tronu z tobołkami w przebraniu cywilnym do Holandii. To też przyzwyczajony do surowej dyscypliny i
                widząc to niebywałe wydarzenie, szeptałem zdumiony czy i to możliwe, by w państwie ładu i bojaźni Bożej mógł
                mieć miejsce bunt i rewolta? W Berlinie zatrzymał się pociąg wiozący żołnierzy ze wschodu. Opowiadali że
                Polacy opanowali <a target="_blank" rel="noreferrer"
                                    href="https://pl.wikipedia.org/wiki/Kr%C3%B3lestwo_Polskie_(kongresowe)">Królestwo
                Kongresowe</a> i
                niedługo to przyjdą do <a target="_blank" rel="noreferrer"
                                          href="https://pl.wikipedia.org/wiki/Pozna%C5%84">Poznania</a> . Tłum żołnierzy
                tymi wieściami
                zbytnio nieprzejmował się i strudzeni długoletnią wojną, żołnierze czynili
              </p>
              <h4 id="second">Uwagi - niech się dzieje co chce, mamy wojny do przesytu, pragniemy pokoju i powracamy do domu.</h4>
            </div>

            <p>
              Dnia 27 grudnia roku Pańskiego 1918 nad grodem <a
              target="_blank" rel="noreferrer" href="https://pl.wikipedia.org/wiki/Pozna%C5%84">Przemysława</a> zabrzmiał
              swym donośnym głosem złoty Róg Wolności. Echo tego symbolicznego rogu jako błyskawica rozległo się po całym
              zaborze <a target="_blank" rel="noreferrer"
                         href="https://pl.wikipedia.org/wiki/Zab%C3%B3r_pruski">pruskim</a> Na dźwięk jego młodzieńcy ze
              wszystkich chat,
              ze wszystkich podwórzy pochopnie i mężnie śpieszą na miejsca zbiórki, by się zaciągnąć do hufców, gotujących
              się do wymarszu na „Pole Chwały". W niedzielę rano 5 stycznia 1919 roku do
              <a target="_blank" rel="noreferrer" href="https://pl.wikipedia.org/wiki/Budzy%C5%84">Budzynia</a> przybyła
              gromadka Sokołów powstańców z
              <a target="_blank" rel="noreferrer" href="https://pl.wikipedia.org/wiki/Rogo%C5%BAno">Rogoźna</a> z
              <a target="_blank" rel="noreferrer" href="https://pl.wikipedia.org/wiki/Wiktor_Skotarczak">Wiktorem
                Skotarczakiem</a> na czele i obsadziła
              Budzyń, uwalniając miasto nasze ze <a target="_blank" rel="noreferrer"
                                                    href="https://pl.wikipedia.org/wiki/Cesarstwo_Niemieckie">Szpon
              orła czarnego</a> po półtorawiekowej niewoli. Oni to pierwsi
              zatknęli chorągwie polskie na gmachach publicznych, powierzając gospodarzowi Lippokowi z pod Budzynia
              (Stanisław Lippok) dowództwo nad miastem. Na drugi dzień Powstańcy wyruszyli na
              <a target="_blank" rel="noreferrer" href="https://pl.wikipedia.org/wiki/Chodzie%C5%BC">Chodzież</a> i miasto
              to zajęli bez walki, atoli gdy z
              <a target="_blank" rel="noreferrer"
                 href="https://pl.wikipedia.org/wiki/Pi%C5%82a_(miasto)">Piły</a> nadciągnął silny oddział
              <a target="_blank" rel="noreferrer" href="https://pl.wikipedia.org/wiki/Grenzschutz_Ost">Heimatschutzu</a> ,
              Polacy wycofali się z Chodzieży i
              wrócili do Budzynia.
              Budzyń, ta mała miejscowość, mocno zniemczona, miała odegrać ważną rolę w historii powstania. I rzeczywiście
              miasteczko nasze w tym przełomowym, historycznym czasie piękne przeżywało chwile. Do Budzynia śpieszyli
              powstańcy z bliska i dalszych okolic zgłaszając się do szeregów. Tu na naszym rynku wrzało i kipiało życie
              żołnierskie. Do Budzynia przyjeżdżali nasi kochani wodzowie, którzy nam tak dzielnie hetmanili, generałowie:
              <a target="_blank" rel="noreferrer" href="https://pl.wikipedia.org/wiki/J%C3%B3zef_Dowbor-Mu%C5%9Bnicki">Dowbor
                Muśnickii</a>
              <a target="_blank" rel="noreferrer" href="https://pl.wikipedia.org/wiki/Kazimierz_Grudzielski">ś.p.
                Grudzielski</a> . Tu w Budzyniu odbył się
              rzadki akt historyczny, który
              jak Budzyń stoi i istnieć będzie, może już się nie powtórzy! Na rynku naszym przy ołtarzu polowym wojsko
              polskie przysięgało wierność Ojczyźnie. Budzyń stał się miejscem strategicznym i koncentracyjnym wojsk
              polskich pododcinka szóstego frontu północnego i za razem kolebką tworzącego się II batalionu sławnego
              <a
                href="https://pw.ipn.gov.pl/pwi/historia/oddzialy-powstancze/8611,Piechota-Wojsk-Wielkopolskich.html?poz=1">4
                pułku
                Strzelców Wielkopolskich (58p.p.)</a> . Budzyń
              przygarniał i tulił do siebie Powstańców, karmił ich, ocierał łzy i goił rany. Wreszcie Budzyń był pierwszy
              skropiony krwią polską w tutejszym powiecie. Na trzeci dzień powstania, gdy na rynku byliśmy zajęci
              przygotowywaniem się do akcji zbrojnej na Chodzież, zjawił się samolot nieprzyjacielski i zabija nam naszego
              dzielnego
              <a target="_blank" rel="noreferrer"
                 href="https://chodziez.naszemiasto.pl/wspomnienie-o-michale-rajewiczu-pierwszym-poleglym/ar/c16-4946114">druha
                śp. Rajewicza</a> . Nadmienić
              mi tu wypada, że zabójca ś.p.Rajewicza był lotnikiem światowej sławy
              <a target="_blank" rel="noreferrer" href="https://en.wikipedia.org/wiki/Max_N%C3%A4ther">Oberleutnant armji
                pruskiej</a> udekorowany licznymi
              orderami, który w wojnie światowej
              zestrzelił 27 samolotów, kilka balonów na uwięzi, przy czym zginęło wielu lotników francuskich. Lecz śmierć
              ś.p. Rajewicza nie ostudziła męstwa w szeregach powstańczych, przeciwnie ściskali oni zawzięcie swoją broń a z
              ich oczu bił święty ogień zapału. I już w następny dzień, gdy nadeszły posiłki z
              <a target="_blank" rel="noreferrer" href="https://pl.wikipedia.org/wiki/Oborniki">Obornik</a> i <a
              href="https://pl.wikipedia.org/wiki/W%C4%85growiec">Wągrowca</a> .
            </p>
            <Divider dashed="true"/>

            <p id="budzyn">
              Gdy 5 stycznia 1919r. przyszli do Budzynia powstańcy, zaraz się do nich przyłączyłem. Pomagałem w
              organizowaniu powstania i w dniu 8 stycznia 1919r. wziąłem czynny udział w bitwie pod
              organizowaniu powstania i w dniu 8 stycznia 1919r. wziąłem czynny udział w bitwie pod
              <a target="_blank" rel="noreferrer" href="https://pl.wikipedia.org/wiki/Bitwa_o_Chodzie%C5%BC">Chodzieżą</a> .
              W pierwszych dniach ćwiczyłem
              rekrutów w mustrze (znanej
              mi dobrze z Sokoła) i uczyłem ostrego strzelania. Gdy przy końcus tycznia nad oddziałami budzyńskimi objął
              dowództwo pan podpór. Bartkowski, rozkazał mi, bym się zajął uzbrajaniem tworzącego sie II baonu 4 pułku
              Strzel. Wlkp. Uzbrojenie powstańców było podówczas marne. Większa część żołnierzy była uzbrojona w dubeltówki,
              pistolety, stare karabiny a nawet kapiszonki i teszyngi. Zacząłem jeździć do Wągrowca, Poznania,
              <a target="_blank" rel="noreferrer" href="https://pl.wikipedia.org/wiki/Gniezno">Gniezna</a> Zwożąc różną
              broń, amunicję i części zapasowe, a
              każdy wóz z bronią
              witali dzielni powstańcy z prawdziwą radością. W toku powstania uzyskałem z Wągrowca dwa granatomioty wraz z
              kilku pudełkami z pociskami, które objęła kompania rogozińska i użyła takowe pod
              <a target="_blank" rel="noreferrer"
                 href="http://opatowka.blink.pl/old/dzieje-po-1918-roku/boj-pod-radwankami/">Radwankami</a> . Nieco później
              z Gniezna dalsze sześć,
              także każda kompania miała po dwa granatomioty. W Łabiszynie odbył się kurs dla granatomiotników,
              przeprowadził go fachowiec pan Leonard Krukowski (obecnie major aktywny) w kursie uczestniczyło nas 17 ludzi.
            </p>
            <Divider dashed="true"/>
            <p> 8 stycznia 1919 roku rychło rano poszła wiara nasza w trzech kolumnach pod dowództwem pana
              <a target="_blank" rel="noreferrer"
                 href="https://pl.wikipedia.org/wiki/W%C5%82odzimierz_Kowalski">Kowalskiego</a> na Chodzież, i tam niejako
              dokończyła dzieła sławnego
              <a target="_blank" rel="noreferrer" href="https://pl.wikipedia.org/wiki/Bartek_zwyci%C4%99zca">Bartka
                Sienkiewiczowskiego</a> . Poszli oni
              ochoczo i pod
              Chodzieżą na własnej ziemi otrzymali swój pierwszy
              <a target="_blank" rel="noreferrer" href="https://pl.wikipedia.org/wiki/Bitwa_o_Chodzie%C5%BC">chrzest
                bojowy</a> . Powiada przysłowie polskie,
              że kto mieczem wojuje od
              miecza ginie. Istotnie tak jest, albowiem w toku owych walk zjawił się znowu poprzednio wzmiankowany lotnik i
              obsypał nas gradem kul. Wtedy powstańcy już jako zwycięzcy skierowali swoją broń i po kilku strzałach
              napastnika zestrzelili, który spadając na zmarzniętą ziemię poniósł śmierć na miejscu. O dziwne zrządzenie
              losu! Ten śmiały lotnik, który drwił sobie z powstańców i na wojnie światowej przez długie miesiące, a może i
              lata na próżno był ostrzeliwany przez baterie francuskie, ginie od lichej broni polskiej: karabinu ręcznego.
              Chodzież została zdobyta krwawo, około 200 zabitych i rannych w tym kilku oficerów, między nimi poległ
              komendant <a target="_blank" rel="noreferrer" href="http://www.landesarchiv-bw.de/plink/?f=6-191546">Chodzieży
                von Stierkorb</a> . Po naszej
              stronie straty
              wynosiły 24 zabitych i rannych. Nazajutrz na rozkaz z <a
                href="https://pl.wikipedia.org/wiki/Pozna%C5%84">Poznania</a>
              wczesnym rankiem Polacy opuścili Chodzież i powrócili do Budzynia, zaś oddziały z dalszych stron odmaszerowały
              do swoich miejscowości tak, że w Budzyniu pozostał tylko oddział rekrutujący się z miejscowych ochotników.
              Ponieważ w myśl układu w Chodzieży nie miało być żadnego wojska, więc w rejonie budzyńskim panował przez
              kilkanaście dni spokój. W tym czasie napływ ochotników do Budzynia był coraz liczniejszy i komendant rejonu
              budzyńskiego Lippok powierzył Czesławowi Hamlingowi tworzenie kompanii budzyńskiej. Po zajęciu Chodzieży przez
              oddziały niemieckie, akcja wojenna odżyła na nowo. Z początku w pierwszej połowie stycznia 1919r. wysyłano
              patrole wywiadowcze pod Chodzież,
              Radwanki,
              <a target="_blank" rel="noreferrer" href="https://pl.wikipedia.org/wiki/Margonin">Margonin</a> i.t.d. lecz w
              drugiej połowie stycznia, gdy
              oddziałki z okolic
              zakwaterowały w Budzyniu na dobre, zaczęła rysować się linia frontu budzyńskiego, która biegła mniej więcej
              przez <a target="_blank" rel="noreferrer"
                       href="https://pl.wikipedia.org/wiki/Ostr%C3%B3wki_(wojew%C3%B3dztwo_wielkopolskie)">Ostrówki</a> ,
              <a target="_blank" rel="noreferrer" href="https://pl.wikipedia.org/wiki/Podanin">Podanin</a> ,
              <a target="_blank" rel="noreferrer"
                 href="https://pl.wikipedia.org/wiki/Podstolice_(powiat_chodzieski)">Podstolice</a> , Radwanki,
              <a target="_blank" rel="noreferrer"
                 href="https://pl.wikipedia.org/wiki/Sypniewo_(powiat_chodzieski)">Sypniewo</a> i z małymi zmianami
              utrzymała się do końca kampanii
              <a target="_blank" rel="noreferrer"
                 href="https://pl.wikipedia.org/wiki/Powstanie_wielkopolskie">polsko-niemieckiej</a> . Dnia 2 lutego1919r. w
              Radwankach
              padli
              <a target="_blank" rel="noreferrer" href="http://poznan.uw.gov.pl/groby-powstancow-wielkopolskich-w-budzyniu">dwaj
                pierwsi powstańcy z Budzynia ś.p. Jackowski i Urbański</a> . Nad ciałami poległych pastwiono się w
              bestialski sposób, piersi były pokłute bagnetami jako przetak.
              <Divider dashed="true"/>
              Od tego czasu walki pod Budzyniem przybrały na
              ostrości, w których Polacy zawsze utrzymali swoje pozycje. W ciągłych utarczkach z wrogiem wiedliśmy boje ze
              zmiennym szczęściem i mieliśmy poważne straty w zabitych i rannych. Padali najlepsi synowie Polski, lecz
              Ojczyzna może być dumną z ofiary życia swoich synów, wszyscy padli godnie, bo każdy cios, każda rana były z
              przodu zadane, znać, że kroku dotrzymali . Pierwszych poległych pochowano obok kościoła. Wielu poległych
              zabrały rodziny by ich pochować na swych
              <a target="_blank" rel="noreferrer"
                 href="https://www.poznan.uw.gov.pl/grob-powstancow-wielkopolskich-w-budzyniu">cmentarzach
                parafialnych</a> . Atoli przy owych walkach przeciwnicy
              jeszcze większe cięgi odbierali na dowód liczne mogiły poległych na niemieckich cmentarzach w Budzyniu,
              Chodzieży, <a target="_blank" rel="noreferrer" href="https://pl.wikipedia.org/wiki/Szamocin">Szamocinie</a> ,
              Pile i.t.d. Poza walkami orężnymi powstańcy
              musieli się mieć na baczności przed szpiegami i różnymi podejrzanymi indywiduami i takich przychwyconych
              osobników eskortowano do naczelnych władz na przesłuchy, a sądy polowe załatwiały ich niecne sprawki.</p>
            <Divider dashed="true"/>
            <h4 id="third">Tworzenie II. Baonu 4 pułku Strzelców Wielkopolskich.</h4>
            <p>
              Drugi baon utworzony był z luźnych oddziałów, które już od początku powstania walczyły na frontach.
              Prawie wszytkie oddziały organizowały się w swoich miejscowościach począwszy od końca grudnia
              1918r do połowy stycznia 1919r. wyłącznie z ochotników i to byłych żołnierzy armii niemiekiej, a przybyły one
              do Budzynia w styczniu względnie lutym, by wzmocnić front budzyński. Z końcem stycznia 1919r. przyjechał do
              Budzynia oficer artylerii podpór. <a target="_blank" rel="noreferrer"
                                                   href="https://pl.wikipedia.org/wiki/Edmund_Bartkowski">Bartkowski</a> .
              Miał on rozkaz
              tworzenia w naszym mieście batalionu piechoty. Lippokowi zaś powierzono nieco później tworzenie Straży
              <a target="_blank" rel="noreferrer" href="https://pl.wikipedia.org/wiki/Stra%C5%BC_Ludowa">Ludowej</a> .
              Energiczny i rzutki oficer, którego powstańcy cenili
              bardzo, zabrał się dopracy, i nieszczędząc trudu i mozołu w krótkim czasie uformował baon, który z początku
              miał nazwę: Baon budzyński 4pułk. Strzel. Wielkopol. W skład baonu budzyńskiego weszły:
            </p>
            <ul>
              <li>1 komp. Rogozińska (później 5 komp.)</li>
              <li> komp. Gnieźnieńska (później 6 komp.)</li>
              <li> komp. Budzyńska (później 7 komp.)</li>
              <li> komp. Barcińska (później 8 komp.)</li>
              <li> komp. komp.kulomiotów Wrzesińska (później II c.k.m.)</li>
            </ul>
            <p>
              Kompanja Rogozińska pod dowództwem Wiktora <a target="_blank" rel="noreferrer"
                                                            href="https://pl.wikipedia.org/wiki/Wiktor_Skotarczak">Skotarczaka</a> od
              samego
              początku zasilała powstanie
              Budzyńskie i przyczyniła się walnie do zdobycia samochodu opancerzonego pod Budzyniem.
              Z kompanią Gnieźnieńską przybył do Budzynia podpór.
              <a target="_blank" rel="noreferrer"
                 href="https://forum.odkrywca.pl/topic/768045-kpt-stanis%C5%82aw-grabianowski-wujek-wojenka/">Grabianowski</a> .
              Do niej przyłączono II
              komp. Rogozińską i przyjęła nazwę drugiej kom. baonu budzińskiego później 6kompanji. Dowództwo nad 6 komp.
              Objął
              <a target="_blank" rel="noreferrer" href="https://pl.wikipedia.org/wiki/Antoni_Skotarczak">Antoni
                Skotarczak</a> . Organizatorem i dowódcą kompanii
              budzyńskiej
              później 7 komp. Był przez krótki czas Czesław Hamling, późniejszymi jej dowódcami byli: zastępca oficera
              Polcyn
              i <a target="_blank" rel="noreferrer"
                   href="http://sppawlowozonskie.szkolnastrona.pl/art,384,powstanie-wielkopolskie-w-naszym-regionie">Wegner</a> .
              Organizatorami komp. Barańskiej późniejszej 8 komp. byli: Zieliński, Franc i Krukowski Leonard. Kompania
              Barcińska staczała ciężkie boje pod <a target="_blank" rel="noreferrer"
                                                     href="https://pl.wikipedia.org/wiki/%C5%81abiszyn">Łabiszynem</a> i
              <a target="_blank" rel="noreferrer" href="https://pl.wikipedia.org/wiki/Szubin">Szubinem</a> i tamże zdobyła
              10 ciężkich kulomiotów, moc amunicji, kuchnię polową i
              wzięła do niewoli
              <a target="_blank" rel="noreferrer"
                 href="https://pw.ipn.gov.pl/pwi/historia/przebieg-walk-powstancz/walki-na-frontach-powst/8656,Walki-na-Froncie-Polnocnym-miedzy-KCYNIA-i-LABISZYNEM-21-stycznia17-lutego-1919-.html">70
                jeńców</a>.
              <Divider dashed="true"/>
              Dnia 22 lutego 1919r. komo.Barcińska odparła na froncie budzyńskim bardzo gwałtowny atak na
              <a target="_blank" rel="noreferrer"
                 href="https://pl.wikipedia.org/wiki/Str%C3%B3%C5%BCewo_(wojew%C3%B3dztwo_wielkopolskie)">Stróźewo</a> .
              Kompania Wrzesińska, późniejszy II komp. c.k.m. przybyła na odcinek budzyński około 7 lutego 1919r.
              Organizatorem komp. Kulomiotów był były oficer armii niemieckiej podpór.
              <a target="_blank" rel="noreferrer" href="https://pl.wikipedia.org/wiki/Alojzy_Nowak_(wojskowy)">Alojzy
                Nowak</a> . Pod jego dowództwem kompania c.k.m. zapisała się
              chlubnie w historii powstania. Usłyszawszy o toczącej się bitwie w grudniu 1918r. pod
              <a target="_blank" rel="noreferrer"
                 href="https://pl.wikipedia.org/wiki/Walki_pod_Zdziechow%C4%85">Zdziechową(pow.Gniezno)</a> spieszy z
              <a target="_blank" rel="noreferrer" href="https://pl.wikipedia.org/wiki/Wrze%C5%9Bnia">Wrześni</a> ze swoją
              kompanią oddziałom gnieźnieńskim na pomoci przyczynił się
              do zupełnego zwycięstwa. Sukces zwycięstwa był olbrzymi, wzięto do niewoli 350 jeńców, w tym kilku oficerów
              wraz
              z pułkownikiem i majorem, 50 koni, 4 kuchnie polowe, 14 ciężkich kulomiotów, dwa pełne wozy z amunicją i
              wszelki
              bagaż batalionu gnieźnieńskiego Heimatschutzu. Podporucznik Nowak objął dowództwo nad baonem po dowódcy
              Bartkowskim, a dowództwo nad kompanią objął sierżant
              <a target="_blank" rel="noreferrer"
                 href="https://powstancy.wrzesnia-info.com/moellenbrock-tadeusz-maksymilian">Moellenbrek</a>/ .
              Objąwszy dowództwo baonu pan podpór. Nowak dokończył i uzupełnił organizacji podległych mu oddziałów.
              Energiczny, czujny i bardzo waleczny, troszczył się wiele o potrzeby swoich żołnierzy, to też żołnierze
              kochali
              go jak ojca i posłuch był wielki. Żołnierzowi imponuje męstwo dzielność i odwaga,
              i te cnoty dowódców porywają masy do wielkich czynów. W walkach pod Budzyniem komendant Nowak pilnował
              wytrwale
              swego odcinka, odpierając częste ataki zaborców. Dowodził baonem przez blisko półtora roku i w bitwie pod
              Swisłoczą nad <a target="_blank" rel="noreferrer"
                               href="https://pl.wikipedia.org/wiki/Bitwa_nad_Berezyn%C4%85_(1920)">Berezyną</a> został
              raniony.
              Później śpieszy na <a target="_blank" rel="noreferrer"
                                    href="https://pl.wikipedia.org/wiki/III_powstanie_%C5%9Bl%C4%85skie">Śląsk</a> i zasila
              swymi
              zdolnościami i doświadczeniem Powstańców śląskich.
            </p>

            <p>
              Sztab II-go baonu 4 pułku Strzel. Wlkp.
              Do tworzącego się sztabu II baonu należeli:
              Dowódca baonu podporucznik Bartkowski, później ppr.Nowak adiutant podpór. Mindak, lekarz doktór Ledziński,
              później doktor Sikora. Sierżant sztabowy Hasiński, później Bolesław Skotarczak.
              Podoficerowie broni Grzeczka Piotr
              Podoficerowie komorowy Musiał
              Podoficerowie aprowizacji Rychlewski
              Podoficerowie łączności W.Skarbiński, później Wróblewski Józef

              <a target="_blank" rel="noreferrer"
                 href="https://chodziez.naszemiasto.pl/powstanie-wielkopolskie-w-chodziezy-pietraszakowie-bracia/ar/c13-4365963">Płatniczy
                Pietraszak Kazimierz</a>
              Wywiad Riibstick (alzatczyk).
              Sanitariuszem przy czerwonym krzyżu, którego założycielką była pani Anna Weydemanowa był sierżant Kalkowski
              Michał (zginał nad Berezyną)
              Drugi baon jak zresztą i inne baony 4pułku Strzel. Wlkp. (58p.p.) tworzył się na froncie, był stale w rejonie
              walk, niepokoił częstymi wypadami nieprzyjaciół, a po rozejmie gdy Heimatschutz się zapominał usiłując
              niekiedy
              przełamać linie polskie, był zawsze krwawo odpierany. Chociaż oddziały II. baonu odpoczynku nie zaznały,
              żołnierz był karny i uświadomiony, a zapał i chęć do boju były doskonałe. Cały pułk już na froncie
              Wielkopolskim
              wyróżnił się walecznością a nawet Niemcy doceniali czwartaków zwanych „Das Grosspolnische 4.eisernes
              Infanterie-Regiment". Po zakończeniu walk na zachodzie nasz baon budzyński wraz z pułkiem w dniu 29 lipca
              1919r.
              wyrusza na Wschód, i po licznych krwawych bojach zatrzymał się nad rzeką Berezyną. Tam to czwartacy zasłynęli
              ze
              swej znajomości bojowej i nieugiętej wytrwałości bijąc często wielokrotnie liczniejsze formacje bolszewickie.
              To
              też wszędzie gdzie pojawili się czwartacy, krasnoarmiejcy drżeli z strachu zowiąc nas „biały żelazny 4 Pułk".
            </p>


            <h4 id="four">Zdobycie Pancernika</h4>
            <p>
              Po ciężkiej klęsce zadanej zaborcy, w dniu 8 stycznia 1919r. pod Chodzieżą został zawarty układ, mocą którego
              miasto Chodzież miało pozostać neutralnym i żadne wojska tak polskie jako i niemieckie nie miały Chodzieży
              zajmować. Niestety, sąsiad nasz słowa nie dotrzymał i po kilku dniach Chodzież zajął. Dowództwo sił
              niemieckich
              w Pile, na które zresztą miała wpływ okoliczna szlachta niemiecka, uznało Budzyń jako miasto buntownicze.
              Postanowiono wysłać ekspedycję karną, zająć Budzyń i znajdujących się w nim insurgentem przykładnie ukarać!
              Insurgent, insurgentem jest to słowo więcej pospolite a oznacza ono niezadowolonych łazików, niespokojnych
              duchów, lub uzbrojone wałęsające się bandy. Z tego więc można sobie wyrobić sąd co czekało nasz biedny Budzyń.
              W
              tym celu sztab niemiecki opracował szczegółowy plan zajęcia Budzynia. Sprowadzono więc artylerię, zatoczono
              działa na pagórki podanińskie, a na wysokiej pompie pana Sprotty w Podaninie urządzono obserwację dla
              artylerii.
              Artyleria miała za zadanie w czasie boju zamknąć ogniem zaporowym wszystkie wyjścia w kierunku południowym i
              nikogo nie wypuścić z Budzynia. Istotnie, padły pociski przy bruku, wiodącym na dworzec w pobliżu młyna, inne
              w
              rejonie glinek, a nawet przy szosie z Budzynia do Rogoźna. Ludzie, którzy znają artylerię wiedzą ,iż
              wstrzelanie
              takowej jest łatwym, wystarczy bowiem oddać dwa,trzy strzały a następny już na pewno siedzi! Poza artylerią
              ściągnięto do Chodzieży dobrze uzbrojoną piechotę w sile podobno około 500 bagnetów (która nam się dała w
              znaki
              pod Podstolicami i Radwonkami) i na koniec sprowadzono ciężki bojowy samochód opancerzony. Sprężyną całej
              akcji
              miał być właśnie ów pancernik <a target="_blank" rel="noreferrer"
                                               href="https://pl.wikipedia.org/wiki/Ehrhardt_E-V/4">Ehrhardt</a> . Podług planu miał
              on się posunąć
              aż
              pod Budzyń, uderzyć na placówki polskie, a będąc już w kontakcie z Polakami miał wystrzelić rakietę sygnałową.
              Na tą rakietę uważał obserwator artylerii w Podaninie. Na dany znak artyleria miała otworzyć ogień zaporowy, a
              piechota ukryta w lesie wyjść do ataku i wraz z pancernikiem pohulać sobie w Budzyniu. Pamiętamy wszyscy te
              pohulanki piechoty pruskiej jeszcze z czasów wojny światowej. Hulała sobie w naszym nieszczęsnym
              <a target="_blank" rel="noreferrer" href="https://pl.wikipedia.org/wiki/Kalisz">Kaliszu</a> lub na Zachodzie w
              Belgii i Francji.
              <Divider dashed="true"/>
              O mającym na Budzyń nastąpić ataku
              wiedziała pono tutejsza ludność niemiecka, podejrzanym jest również fakt, że ówczesny komisarz obwodowy
              Gaudig(oficer rezerwy w randze kapitana artylerii), będący stale pod strażą, w chwili gdy pękły pierwsze
              pociski
              armatnie pod Budzyniem, wybiegł z biura, pobiegł na poddasze, i z okienka obserwował uderzające granaty. Zanim
              pobiegł dozorujący go Leon Stepczyński i sprowadził go do biura. Do jakiego stopnia lekceważono sobie siły
              polskie niech posłuży fakt, że pewien oficer, hrabia Schwerin, który gdy w Chodzieży z ciekawości wsiadał do
              pancernika i pono do obsługi nie należał, to z publiczności, gdy jedna z pań zawołała, panie hrabio, niech pan
              pozostanie tutaj! Hrabia uśmiechając się odpowiedział — chcę użyć przejażdżki do Budzynia. Dosłownie: Ich will
              bios eine Vergnügungsfahrt nach Budzyń unternehmen! Dowództwo polskie wiedziało coś o owych przygotowaniach
              nieprzyjacielskich. Wiedzieliśmy wszyscy o artylerii, gromadzącej się piechocie i o pancerniku, a nawet, że
              pono
              ich było więcej, a toli nie wiedzieliśmy kiedy i w jakim planie nastąpi atak. Dla pewności placówki nasze
              wszędzie wzmocniono, poza tym była mała rezerwa w mieście, do której ja też należałem. Dnia szóstego lutego
              zagrzmiały armaty poza lasem i w tej chwili ponad Budzyniem ze świstem przeleciały pociski armatnie, nie
              wyrządzając żadnych szkód, ogień był nieregularny. Pod wieczór ogień ustał i nastała względna cisza. I owa
              cisza
              wywołała w Budzyniu niepokój i zdenerwowanie. Wielu żołnierzy zamiast bronić swego rodzinnego miasta,
              porzucili
              broń i bez powodu pouciekali do Rogoźna. Rzecz szczególna, że uciekali nawet ludzie tacy, którzy bezpośrednio
              z
              powstaniem nie mieli nic wspólnego i ruchowi powstańczemu przyglądali się z okien.
              Na szczęście późnym wieczorem przyszły posiłki z Rogoźna i zajęły opuszczone posterunki. Noc minęła
              spokojnie. Lecz już z rana dnia 7 lutego 1919r. doszły do Budzynia wieści, że w dniu tym nastąpi atak.
              Szczupłe
              siły polskie na Okręgliku, nie posiadające ani jednego karabinu przeciwczołgowego, ani amunicji stalowej,
              postanowiły bronić Budzynia do ostatniej kropli krwi. Pod nadzorem doświadczonego z wojny światowej Wiktora
              Skotarczaka z Rogoźna, po obu stronach szosy w laskach usadowili się Powstańcy, zaś po prawej stronie szosy
              przy
              kulomiocie dowódca ustawił młodego, dziewiętnastoletniego celowniczego, strzelca dobrego, Stanisława
              Kozłowskiego a jako taśmowego do pomocy dodał mu Wikarjusza Szczepana. Powstańcy w piasku wygrzebali dołki i
              zamaskowawszy się dobrze zielenią świerczyny czekali zaczajeni na przybycie wroga. O, nierówny to był podział
              sił!
              <Divider dashed="true"/>
              Po tamtej stronie potężny, dobrze uzbrojony, zemstą dyszący wróg, a po naszej stronie garstka około 16
              powstańców, którzy gotowi byli drogo sprzedać swoje życie w obronie swoich ukochanych zagonów piastowskich. O
              tak, nierówny to był podział sił, ale nad nami po naszej stronie czuwała wyższa siła. Wyższa moc! Było już
              dobrze z południa około godziny trzeciej, gdy z mroków leśnych wyłonił się szary, olbrzymi potwór stalowy.
              Jedzie, jedzie! Szeptali sobie nasi żołnierze. Tymczasem potwór ostrożnie posuwał się naprzód, i w odległości
              jednego staja przed Okręglikiem zwolnił biegu, i Powstańcy mogli już dostrzec wyzierające z luk lufy
              kulomiotów.
              W tej sekundzie nasz dzielny Kozłowski z Ryczywołu pociągnął za cyngiel swego kulomiotu, mierząc w szparę,
              przez
              którą patrzał sterujący pancernikiem szofer, i gdy kulomiot zagrał, za nim huknęły wszystkie karabiny polskie.
              Z
              pancernika wystrzelono rakietę sygnałową, która wzięła kierunek zbyt poziomy, tak że obserwator artylerii w
              Podaninie jej nie dostrzegł. Powstańcy wyskoczyli z okopów i jakol wyrzucili się ku pancernikowi a Napiecek
              rzucił ręczny granat, przy czym szybko opanowano pancernik. Jak się później okazało, jedna z kul polskich
              wystrzelona z kulomiotu zadecydowała o losie pancernika. A mianowicie wpadła ona przez szparę do wnętrza
              samochodu i ugodziła śmiertelnie siedzącego przy sterze szofera, dalsze kule, które wpadły przez szparę i lukę
              do kulomiotu zabiły dwóch, a jedna kula przebiła tarcz odrzutnikową i rozerwała chłodnicę kulomiotu. Trafiony
              szofer puścił kierownicę, przez co pancernik stoczył się do rowu, przy czym w montowane i do strzału
              przygotowane kulomioty na skutek pochylenia pojazdu otrzymały inny kierunek i w momencie nie były stosowne do
              strzelania. Odważny hrabia Schwerin wychylił się z pancernika i ogniem z
              <a target="_blank" rel="noreferrer"
                 href="https://pl.wikipedia.org/wiki/Pistolet_Parabellum">parabelli</a> usiłował powstrzymać ruszających do
              ataku Powstańców, lecz
              śmiały i spokojny Kozłowski wziął go na muszkę i hrabia przeszyty sześcioma kulami padł martwy na ziemię. Po
              opanowaniu pancernika Powstańcy zdumieni swoim czynem szeptali, cud, to cud! Istotnie dał się w tym wydarzeniu
              wyczuć palec Boży, i ten palec Boży tchnął pewien lęk napiechotę nieprzyjacielską, stojącą w lesie tak, że ci
              zahartowani wojowicy
              zpod <a target="_blank" rel="noreferrer" href="https://pl.wikipedia.org/wiki/Bitwa_pod_Verdun">Verdunu</a> ,
              <a target="_blank" rel="noreferrer" href="https://pl.wikipedia.org/wiki/II_bitwa_nad_Marn%C4%85">Marny</a> i
              <a target="_blank" rel="noreferrer"
                 href="https://en.wikipedia.org/wiki/Battle_of_Soissons_(1918)">Soissonu</a> nie przyszli pancernikowi z
              pomocą, i w panicznym
              strachu cofnęli się, spiesznie do Chodzieży. Jeden, jedyny żołnierz z obsługi pancernika zdołał zbiec, lecz
              jak
              później nas doszły wieści, zmarł on pono w szpitalu z odniesionych ran, inni polegli a drugi szofer dostał się
              do niewoli. Po stronie naszej nie było żadnych strat.
            </p>
            <p> Podaję nazwiska poległych. (Niemców)</p>
            <ul>
              <li> Leutnant Kurt Amos, komendant pancernika.</li>
              <li> Leutnant Graf von Schwerin-Janow. (Leutnant Joachim Graf von Schwerin-Janow)</li>
              <li> Priifter, celowniczy kulomiotu.</li>
              <li> Sundermann, obsługa</li>
              <li> Kurt Heidler Gollnet, szofer.</li>
            </ul>


            <p>
              Poległych wojowników zwyczajem rycerskim z honorami wojskowymi pochowano na tutejszym cmentarzu ewangelickim.
              Łupem naszym stał się cały i nieuszkodzony pancernik, o którego ściany kule odbiły się jak groch o deskę.
              Kierowało nim dwóch szoferów, a na dachu była umieszczona wieża podobna do kopuły, w której były luki do
              strzelania. W pancerniku był znaczny materiał i to:
            </p>

            <ul>
              <li>osiem tysięcy naboi kulomiotowych w taśmach,</li>
              <li>siedemset granatów ręcznych,</li>
              <li>cztery kulomioty,</li>
              <li>dwa pistolety sygnałowe wraz z pudełkiem naboi sygnałowych.</li>
            </ul>

            <p>
              Tak więc oto dzięki niespożytej sile ducha i wiary w własne siły, nasi Powstańcy ocalili Budzyń i tym samym
              dalsze okolice od groźnej inwazji zaborcy! I ten szary Powstaniec dumny ze swoich zwycięstw,
              nie wyciągnął ręki po zapłatę, ale skwapliwie szlakami swoich ojców podążył na wschód, daleko hen, aż nad
              <a target="_blank" rel="noreferrer"
                 href="https://pl.wikipedia.org/wiki/Bitwa_nad_Berezyn%C4%85_(1920)">Berezynę</a> uwalniać tamtejsze zagony
              piastowskie zpod
              panowania rosyjskiego. A musiał się borykać i walczyć w bardzo trudnych warunkach materialnych i
              higienicznych.
              I choć wszy go gryzły i mróz siekł jego ciało, on nie ustawał w swojej zbożnej pracy i pomnażał stale chwałę
              Ojczyźnie. Często też, niestety, o bardzo często, dlatego biedaczka zabrakło kawałka chleba i wiązki słomy na
              nocleg. Wielu też z pośród nich strudzonych i znękanych przykryła mogiła, a miejsca ich spoczynku, znajdujące
              się częściowo po tamtej stronie granicy, są dziś opuszczone i zaniedbane. Nikt nie idzie odwiedzić ich
              samotnych
              mogiłek, żadna litościwa dusza nie przyklęknie i nie westchnie nad ich zapadłymi grobami. Oj, ciężki jest los
              powstańca, tułacza! Cześć Wam Bohaterowie, cześć Waszym prochom, niech że ta ziemia, za którą swoje życie
              oddaliście w ofierze, lekką Wam będzie!
              Mają niektóre miasta polskie swoje historyczne miejsca otoczone aureolą chwały i sławy!
              Maje <a target="_blank" rel="noreferrer" href="https://pl.wikipedia.org/wiki/Lw%C3%B3w">Lwów</a> ,<a
              href="https://pl.wikipedia.org/wiki/Krak%C3%B3w">Kraków</a>,
              <a target="_blank" rel="noreferrer" href="https://pl.wikipedia.org/wiki/Warszawa">Warszawa</a> , <a
              href="https://pl.wikipedia.org/wiki/Ostro%C5%82%C4%99ka">Ostrołęka</a> szczyci się
              sławny mmiejscem <a target="_blank" rel="noreferrer"
                                  href="https://pl.wikipedia.org/wiki/Wrze%C5%9Bnia">Września</a> ,
              <a target="_blank" rel="noreferrer"
                 href="https://pl.wikipedia.org/wiki/Ksi%C4%85%C5%BC_(Wa%C5%82brzych)">Książ</a> ,
              <a target="_blank" rel="noreferrer" href="https://pl.wikipedia.org/wiki/%C5%81abiszyn">Łabiszyn</a> i inne
              miejscowości. Lecz takie miejsce
              historyczne i sławne posiada
              także nasze miasteczko Budzyń, a miejscem tym jest nasz Okręglik z figurą Matki
              Boskiej. To poważne, zaciszne miejsce jest czczone i chętnie odwiedzane przez tutejszą ludność budzyńską.
              Jeszcze w czasie powstania, ilekroć szli szosą Powstańcy i choć po drodze śpiewali popularną piosnkę o
              pancerniku, to gdy się zbliżali do figury, wtedy milkli, zdejmowali czapki i oddawali cześć świętymu miejscu.
              I
              tą cześć można najlepiej zaobserwować w czasie procesji dni krzyżowych. Najliczniejsze procesje bywają na
              Okręgliku. Biorą w tej procesji udział stany, idą młodzi i starsi a nawet staruszkowie O kijaszkach w ręku
              podążają coby tam na Pagórku pomodlić się. A w dobie dzisiejszej pobożne niewiasty i dzieci sadzą tam kwiaty i
              przyozdabiają figurę w zieleń i girlandy.
            </p>

            <p>
              Drogi bracie Powstańcze Wojaku! Jeżeli w tych ciężkich czasach nawiedzi cię nieszczęście i obarczony kłopotami
              będziesz przypadkiem przechodzić prze zOkręglik, obok owego miejsca Chwały i Sławy, to zatrzymaj się na
              chwilkę,
              a choćby tylko na jedną minutę, i przypomnij sobie te wielkie momenty, gdy niegdyś na tym miejscu składałeś
              swoje życie na ołtarzu Ojczyzny i własną piersią zastawiałeś Budzyń. A następnie z wiarą spojrzyj na pagórek
              ku
              figurze naszej Królowej. A Ona, jak ongiś, tak też i w dobie teraźniejszej wzmocni i pokrzepi twego ducha tymi
              słowy:
            </p>
            <p>Przechodniu smutny, bądź w Bogu ufny! <br/>
              zbrojowniczy II batalionu 4-go Pułku Strzelców Wielkopolskich (58p.p.)
              Piotr Grzeczka</p>

          </div>
        </div>
      </div>
    </div>
  );
}

export default Products;
